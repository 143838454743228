import { environment } from "../../../environments/environment";

export class APP_ROUTES {
    public static root: string = '';
    public static slash: string = '/';
    public static previous: string = '../';
    public static new: string = 'new';
  
    //* Auth routes */
    public static login: string = 'login';
    public static forgotPassword: string = 'forgot-password';

    public static dashboard: string = 'dashboard';
    public static profile: string = 'profile';
    public static EnrolleForm: string = "enrolle-create-user-account"
    public static team: string = 'team';
    public static requests: string = 'requests';
    public static users: string = 'users';
    public static settings: string = 'settings';
    public static vehicle: string = 'vehicle';
    public static services: string = 'services';
    public static reports: string = 'reports';
    public static notification: string = 'notification';
    public static notes: string = 'notes';
    public static billing: string = 'billing';
    public static contactUs: string = environment.landingUrl + 'contact';
    public static pra: string = environment.landingUrl + '';
    public static privacy: string = environment.landingUrl + 'privacy-policy';
    public static terms: string = environment.landingUrl + 'terms-of-service';
}