import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LocalStorage } from "./util/storage/localStorage.service";
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { InternetInterceptor, ResponseInterceptor } from './interceptors';
import { AuthService } from './@core/services/auth.service';
import { AuthGuard } from './@core/guards/auth-guard.service';
import { UnAuthGuard } from './@core/guards/unauth-guard.service';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { RequestInterceptor } from './interceptors/request.interceptor';
import { DatePipe } from '@angular/common';

@NgModule({
	declarations: [
		AppComponent,
	],

	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		ToastModule,
		BrowserAnimationsModule
	],
	providers: [
		AuthService,
		AuthGuard,
		UnAuthGuard,
		LocalStorage,
		MessageService,
    DatePipe,
		{ provide: HTTP_INTERCEPTORS, useClass: InternetInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
	],
	bootstrap: [AppComponent]
})

export class AppModule { }
