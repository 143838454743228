import { environment } from "../../../environments/environment";

export class ApiRoutes {
  // api base url

  public static apiBaseUrl: string = environment.apiBaseUrl;

  public static get ipConfig(): string {
    return 'https://ipapi.co/json/';
  }

  // auth
  public static get login(): string {
    return this.apiBaseUrl + 'login';
  }

  public static get sendOtp(): string {
    return this.apiBaseUrl + 'send_otp';
  }

  public static get verifyOtp(): string {
    return this.apiBaseUrl + 'verify_otp';
  }

  public static get forgetPassword(): string {
    return this.apiBaseUrl + 'forget_password';
  }

  public static get setPassword(): string {
    return this.apiBaseUrl + 'set_password';
  }

  public static get users(): string {
    return this.apiBaseUrl + 'user/list';
  }

  public static get userDetail(): string {
    return this.apiBaseUrl + 'user/';
  }

  public static get getTPAndMPDetails(): string {
    return this.apiBaseUrl + 'service/list/';
  }

  public static get userDelete(): string {
    return this.apiBaseUrl + 'user/delete/';
  }

  public static get userVerify(): string {
    return this.apiBaseUrl + 'user/verify/';
  }

  public static get userUnVerify(): string {
    return this.apiBaseUrl + 'user/unverify/';
  }

  public static get userMarkAsResident(): string {
    return this.apiBaseUrl + 'user/mark_unmark_as_resident/';
  }

  public static get userAccountAction(): string {
    return this.apiBaseUrl + 'user/account_action/';
  }

  public static get mpList(): string {
    return this.apiBaseUrl + 'user/list_management';
  }

  public static get states(): string {
    return this.apiBaseUrl + 'state';
  }

  public static get verifyUser(): string {
    return this.apiBaseUrl + 'user/verify/';
  }

  public static get userDocsList(): string {
    return this.apiBaseUrl + 'list_docs/';
  }

  public static get docTypes(): string {
    return this.apiBaseUrl + 'list_docs_type';
  }

  public static get docUpload(): string {
    return this.apiBaseUrl + 'upload_docs_by_admin/';
  }

  public static get docDelete(): string {
    return this.apiBaseUrl + 'delete_docs/';
  }

  public static get docVerify(): string {
    return this.apiBaseUrl + 'verify_docs/';
  }

  public static get docUpdate(): string {
    return this.apiBaseUrl + 'update_docs/';
  }

  public static get userCreate(): string {
    return this.apiBaseUrl + 'user/create';
  }

  public static get userEdit(): string {
    return this.apiBaseUrl + 'user/update/';
  }

  public static get addressEdit(): string {
    return this.apiBaseUrl + 'user/update_address/';
  }

  public static get userUpdate(): string {
    return this.apiBaseUrl + 'user/update';
  }

  public static get profile(): string {
    return this.apiBaseUrl + 'profile';
  }

  public static get tpList(): string {
    return this.apiBaseUrl + 'serviceprovider';
  }

  public static get assignMP(): string {
    return this.apiBaseUrl + 'user/assign_medical_provider/';
  }

  public static get assignTP(): string {
    return this.apiBaseUrl + 'user/assign_transport_provider/';
  }

  public static get vehicles(): string {
    return this.apiBaseUrl + 'vehicle/list';
  }

  public static get vehicle(): string {
    return this.apiBaseUrl + 'vehicle/';
  }

  public static get createVehicle(): string {
    return this.apiBaseUrl + 'vehicle/add';
  }

  public static get updateVehicle(): string {
    return this.apiBaseUrl + 'vehicle/update/';
  }

  public static get deleteVehicle(): string {
    return this.apiBaseUrl + 'vehicle/delete/';
  }

  public static get vehicleVerify(): string {
    return this.apiBaseUrl + 'vehicle/verify/';
  }

  public static get vehicleAccountAction(): string {
    return this.apiBaseUrl + 'vehicle/action/';
  }

  public static get addTpToDriver(): string {
    return this.apiBaseUrl + 'tp-driver';
  }

  public static get addServiceTpAndMp(): string {
    return this.apiBaseUrl + 'service/create/';
  }

  public static get driverTpList(): string {
    return this.apiBaseUrl + 'list_sp_contractor';
  }

  public static get driverTpPresense(): string {
    return this.apiBaseUrl + 'tp-driver-presence';
  }

  public static get removeTpDriver(): string {
    return this.apiBaseUrl + 'remove-tp-driver/';
  }

  public static get vehicleLinkDriver(): string {
    return this.apiBaseUrl + 'vehicle/link_driver/';
  }

  public static get driverList(): string {
    return this.apiBaseUrl + 'user/contractor';
  }

  public static get enrolleeList(): string {
    return this.apiBaseUrl + 'user/list_enrollee';
  }

  public static get tenantList(): string {
    return this.apiBaseUrl + 'user/list_tenants';
  }

  public static get removeDriver(): string {
    return this.apiBaseUrl + 'vehicle/remove_driver/';
  }

  public static get vehicleInfo(): string {
    return this.apiBaseUrl + 'vehicle/contractor_vehicle/';
  }

  public static get removeEnrolleeService(): string {
    return this.apiBaseUrl + 'service/delete/';
  }

  public static get makeDefaultService(): string {
    return this.apiBaseUrl + 'service/set_default/';
  }

  public static get vehicleDocUpload(): string {
    return this.apiBaseUrl + 'upload_vehicle_docs/';
  }

  public static get vehicleDocsList(): string {
    return this.apiBaseUrl + 'list_vehicle_docs/';
  }

  public static get makeTpDefault(): string {
    return this.apiBaseUrl + 'make_tp_default/';
  }

  // Trips
  public static get addTrips(): string {
    return this.apiBaseUrl + 'trip/add';
  }

  // Requets
  public static get addRequest(): string {
    return this.apiBaseUrl + 'request/add';
  }

  public static get serviceProvider(): string {
    return this.apiBaseUrl + 'serviceprovider';
  }

  public static get trips(): string {
    return this.apiBaseUrl + 'trip/fetch';
  }

  public static get requests(): string {
    return this.apiBaseUrl + 'request/list';
  }

  public static get request(): string {
    return this.apiBaseUrl + 'request/list';
  }

  public static get attestation(): string {
    return this.apiBaseUrl + 'trip/attestation';
  }

  public static get assignTp(): string {
    return this.apiBaseUrl + 'trip/assign_to_tp';
  }

  public static get assignContractor(): string {
    return this.apiBaseUrl + 'request/assign_contractor';
  }

  public static get assignMp(): string {
    return this.apiBaseUrl + 'trip/assign_to_mp';
  }

  public static get trip(): string {
    return this.apiBaseUrl + 'trip/detail/';
  }

  public static get tripTraking(): string {
    return this.apiBaseUrl + 'trip/location_tracking/';
  }

  public static get activity(): string {
    return this.apiBaseUrl + 'trip/activity/';
  }

  public static get tripAction(): string {
    return this.apiBaseUrl + 'trip/trip_action';
  }

  public static get tripMileStones(): string {
    return this.apiBaseUrl + 'trip/milestone/';
  }

  public static get tripConfirm(): string {
    return this.apiBaseUrl + 'trip/confirm/';
  }

  public static get tripApprove(): string {
    return this.apiBaseUrl + 'trip/approve/';
  }

  public static get tripUpdate(): string {
    return this.apiBaseUrl + 'trip/update/';
  }

  public static get noteCreate(): string {
    return this.apiBaseUrl + 'notes/add';
  }

  public static get noteFetch(): string {
    return this.apiBaseUrl + 'notes/fetch';
  }

  public static get notification(): string {
    return this.apiBaseUrl + 'notification/fetch';
  }

  public static get notifyUpdateStatus(): string {
    return this.apiBaseUrl + 'notification/update/read_status';
  }

  public static get generateAPIKey(): string {
    return this.apiBaseUrl + 'generate_public_api_key';
  }

  public static get fetchAPIKey(): string {
    return this.apiBaseUrl + 'fetch_public_api_key';
  }

  public static get updateAPIKey(): string {
    return this.apiBaseUrl + 'update_public_api_key_status/';
  }

  public static get portalApps(): string {
    return this.apiBaseUrl + 'shortcuts/fetch';
  }

  public static get portalAppsAdd(): string {
    return this.apiBaseUrl + 'shortcuts/add';
  }

  public static get updateAppsAndPortal(): string {
    return this.apiBaseUrl + 'shortcuts/update';
  }

  public static get deleteAppsAndPortal(): string {
    return this.apiBaseUrl + 'shortcuts/delete/';
  }

  public static get updateShortcut(): string {
    return this.apiBaseUrl + 'shortcuts/fetchById/';
  }

  public static get accessType(): string {
    return this.apiBaseUrl + 'shortcuts/access_type';
  }

  public static get defaultStatusUpdate(): string {
    return this.apiBaseUrl + 'shortcuts/active_deactive';
  }

  public static get shortCutsDataChangeStatus(): string {
    return this.apiBaseUrl + 'shortcuts/change_status';
  }

  public static get managementType(): string {
    return this.apiBaseUrl + 'management_type';
  }

  public static get serviceProviderType(): string {
    return this.apiBaseUrl + 'service_provider_type';
  }

  public static get contractorType(): string {
    return this.apiBaseUrl + 'contractor_type';
  }

  public static get getServiceprovider(): string {
    return this.apiBaseUrl + 'serviceprovider?user_type=4';
  }

  public static get getContractorListForRequestAssign(): string {
    return this.apiBaseUrl + 'user/list_contractor';
  }

  public static get getServiceProviderListForRequestAssign(): string {
    return this.apiBaseUrl + 'user/list_service_providers?fetch=all';
  }

  public static get getManagementListForRequestAssign(): string {
    return this.apiBaseUrl + 'user/list_management';
  }

  public static get getPreferredList(): string {
    return this.apiBaseUrl + 'fetch_preferred_user/';
  }

  public static get getPreferredBlockedList(): string {
    return this.apiBaseUrl + 'preferred_user/blocked_user';
  }

  public static get getPreferredContactorList(): string {
    return this.apiBaseUrl + 'user/contractor';
  }

  public static get deletePreferredUser(): string {
    return this.apiBaseUrl + 'remove_preferred_user/';
  }

  public static get addPreferredUser(): string {
    return this.apiBaseUrl + 'add_preferred_user/';
  }

  public static get blockPreferredUser(): string {
    return this.apiBaseUrl + 'preferred_user/block_action/';
  }

  public static get getRequestStatusList(): string {
    return this.apiBaseUrl + 'request/statusesList/';
  }
}